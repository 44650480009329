import { AppBar, Box, Button, IconButton, Stack, Toolbar, Typography } from "@mui/material";
import logo from "../snapdragon.png";
import React from "react";
import { useAuthContext } from "../auth/useAuthContext";
import AccountPopover from "../components/AccountPopover";

const Header = () => {
  const { login, isAuthenticated } = useAuthContext();

  return (
    <AppBar position="fixed">
      <Stack direction="row" justifyContent="space-between">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            <img
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%'
              }}
              src={logo}
              className="logo"
              alt="logo"
            />
          </IconButton>
          <Box sx={{ flexGrow: 1, alignItems: 'center', display: 'flex', gap: 5 }}>
            <Box>
              <Typography variant="h5" noWrap component="div" sx={{ flexGrow: 1 }}>Snapdragon</Typography>
              <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>Digital Chassis</Typography>
            </Box>
            <Typography variant="h4" noWrap component="div" sx={{ flexGrow: 1 }}>WORKBENCH</Typography>
          </Box>
        </Toolbar>
        <Toolbar>
          {isAuthenticated ? (
            <AccountPopover />
          ): (
            <Button
              onClick={login}
              fullWidth
              color="inherit"
              sx={{ fontWeight: 900 }}
            >
              Log In
            </Button>
          )}
        </Toolbar>
      </Stack>
    </AppBar>
  )
}

export default Header;