import React from "react";

// aws-amplify
import { Amplify } from "aws-amplify";

import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";

// @mui
import { Container } from '@mui/material';

import { SnackbarProvider } from "notistack";

// auth
import { AuthProvider } from "./auth/AwsCognitoContext";
import Header from "./section/Header";
import Home from "./section/Home";

// utils
import config from './utils/config-global';
// import config from './utils/aws-exports';

// config
Amplify.configure(config);

cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);

function App() {
  return (
    <AuthProvider>
      <SnackbarProvider>
        <Container
          component="main"
          maxWidth="sm"
          sx={{
            pt: 12,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Header/>
          <Home/>
        </Container>
      </SnackbarProvider>
    </AuthProvider>
  );
}

export default App;
