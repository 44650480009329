import MainRequest from "./MainRequest";
import { Avatar, Box, Button, Card, Stack } from "@mui/material";
import React from "react";
import { useAuthContext } from "../auth/useAuthContext";
import Typography from "@mui/material/Typography";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const Home = () => {
  const { login, isAuthenticated, token, tokenID } = useAuthContext();

  return isAuthenticated ? (
      <MainRequest
        token={token}
        tokenID={tokenID}
      />
    ) : (
      <Card sx={{ p: 4, mt: 22, width: '100%' }}>
        <Stack width="100%" justifyContent="center" alignItems="center" direction="column">
          <Avatar sx={{ m: 1, bgcolor: '#0d0c88' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h4">
            Please log in to your account.
          </Typography>
          <Box sx={{ mt: 6, width: '100%' }}>
            <Button fullWidth variant="contained" size="large" onClick={login}>
              Log In
            </Button>
          </Box>
        </Stack>
      </Card>
    )
}

export default Home;