/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const config = {
    Auth: {
        Cognito: {
            userPoolClientId: '3isgdje1arbpmde739r2tpjvv7',
            userPoolId: 'us-west-2_hwsG8rR7A',
            loginWith: {
                oauth: {
                    domain: 'sdv-wb-ad-federation.auth.us-west-2.amazoncognito.com',
                    scopes: ['openid', 'email', 'phone', 'profile', 'aws.cognito.signin.user.admin'],
                    redirectSignIn: ['http://localhost:3000/', 'https://main.dwrlju9udi0z1.amplifyapp.com/', 'https://sdv.qualcomm.com/'],
                    redirectSignOut: ['http://localhost:3000/', 'https://main.dwrlju9udi0z1.amplifyapp.com/', 'https://sdv.qualcomm.com/'],
                    responseType: 'code',
                }
            }
        }
    },
    API: {
        REST: {
            apifcd43b70: {
                endpoint: 'https://0zj60i9dz1.execute-api.us-west-2.amazonaws.com/dev',
            }
        }
    }
};


export default config;
