import { useState} from 'react';
import { get, post } from "aws-amplify/api";
import { useAuthContext } from "../auth/useAuthContext";
import { useSnackbar } from "notistack";


const myAPI = "apifcd43b70"
const path = "/workbench"

const useRequest = () => {
  const { token, tokenID, } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setLoading] = useState(false);

  const [getRequest, setGetRequest] = useState({ status: 0, response: null });
  const [postRequest, setPostRequest] = useState({ status: 0, response: null });

  const [error, setError] = useState();

  const [password, setPassword] = useState();

  // Function send post request when provision workbench call is made
  async function provisionWB({ soc, softwareproduct, appdeployment }) {
    try {
      setLoading(true);
      const restOperation = post({
        apiName: myAPI,
        path: path,
        options: {
          body: {
            soctype: soc,
            softwareproducttype: softwareproduct,
            appdeploymenttype: appdeployment,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: token
          },
          queryParams:{
            'userjwt': tokenID
          }
        }
      });
      const { body } = await restOperation.response;
      const response = await body.json();
      console.log('response', response);

      if (response) {
        setPostRequest({status: 200, response});
        enqueueSnackbar(response, {variant: 'success'});
        setPassword(null);
        refresh({soc, softwareproduct, appdeployment});
      }
      // } else {
      //   setPostRequest({ status: 500, response: null })
      //   enqueueSnackbar(response ?? 'Error', { variant: 'error' });
      // }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
      console.log('POST call failed:');
      enqueueSnackbar('POST call failed', {variant: 'error'});
      console.log(e);
    }
  }


  // Function send get request when get workbench details call is made
  async function refresh({ soc, softwareproduct, appdeployment }) {
    try {
      const restOperation = get({
        apiName: myAPI,
        path: path,
        options: {
          queryParams: {
            'userjwt': tokenID,
            soc,
            softwareproduct,
            appdeployment
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: token
          }
        }
      });

      const { body } = await restOperation.response;
      const response = await body.json();

      if (response) {
        console.log('response', response);
        if (response?.WBProvisionedStatus === "Provisioned") {
          setGetRequest({ status: 200, response })
          // enqueueSnackbar(response, {variant: 'info'});
        }
        else {
          setGetRequest({ status: 200, response });
          enqueueSnackbar(response, { variant: 'info' });
        }
      }
    } catch (e) {
      setGetRequest({ status: 500, response: null })
      console.log('GET call failed:');
      console.log(e);
      setError(e);
      enqueueSnackbar(e, { variant: 'error' });
    }
    setLoading(false);
  }

  // Function to do get call to get Password
  async function getPassword({ soc, softwareproduct, appdeployment }) {
    try {
      const restOperation = get({
        apiName: myAPI,
        path: `${path}/accesspassword`,
        options: {
          queryParams: {
            'userjwt': tokenID,
            soc,
            softwareproduct,
            appdeployment
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: token
          }
        }
      });

      const { body } = await restOperation.response;
      const response = await body.json();
      console.log('response', response);
      if (response) {
        enqueueSnackbar('Password received. It will disappear in 60 seconds.', {variant: 'success'});
      }

      setPassword(response);
    } catch (e) {
      setLoading(false);
      setError(e);
      enqueueSnackbar(`GET call failed: ${e}`, { variant: 'error' });
      console.log('GET call failed:', e);
    }
  }

  function hidePassword() {
    setPassword(null);
    enqueueSnackbar(`Password was hidden for security purposes.`, { variant: 'info' });

  }

  return {
    isLoading,
    //
    getRequest,
    postRequest,
    password,
    //
    error,
    //
    provisionWB,
    getPassword,
    hidePassword,
    refresh
  }
};

export default useRequest;