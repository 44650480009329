/* eslint no-return-await: "error" */
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer
} from 'react';

import PropTypes from 'prop-types';
import { Hub } from "aws-amplify/utils";

import {
  fetchAuthSession,
  getCurrentUser,
  signInWithRedirect,
  signOut,
} from "aws-amplify/auth";

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  tokenID: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH':
      return {
        isInitialized: true,
        isAuthenticated: action.payload.isAuthenticated,
        user: action.payload.user,
        token: action.payload.token,
        tokenID: action.payload.tokenID,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
        tokenID: null,
      };
    default:
      return state
  }
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getSession = useCallback(
    async () => {
      try {
        const infoUser = await getCurrentUser();
        if (infoUser) {
          const username_array = infoUser?.username?.split("_");
          const user_final = username_array[username_array.length > 1 ? 1 : 0];

          const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};

          dispatch({
            type: 'AUTH',
            payload: {
              isAuthenticated: true,
              user: {
                name: user_final,
                ...infoUser
              },
              token: accessToken,
              tokenID: idToken,
            },
          });
        } else {
          dispatch({
            type: 'AUTH',
            payload: {
              ...initialState
            },
          });
        }
      } catch (e) {
        dispatch({
          type: 'AUTH',
          payload: {
            ...initialState
          },
        });
        console.log(e);
      }
    }, // eslint-disable-next-line
    []
  );

  const initialize = useCallback(async () => {
    try {
      await getSession();
    } catch {
      dispatch({
        type: 'AUTH',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [getSession]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signedIn":
          console.log('User have been signedIn successfully.');
          getSession();
          break;
        case "signInWithRedirect":
          getSession();
          break;
        case "signedOut":
          dispatch({
            type: 'LOGOUT',
            payload: {
              ...initialState
            },
          });
          break;
        case "signInWithRedirect_failure":
          console.log('failure while trying to resolve signInWithRedirect API.');
          dispatch({
            type: 'LOGOUT',
            payload: {
              ...initialState
            },
          });
          break;
        default:
          break;
      }
    });
    // eslint-disable-next-line
  }, []);


  // LOGIN
  const login = useCallback(
    async () => {
      // eslint-disable-next-line
      await signInWithRedirect({
        provider: {
          custom: "Google"
        }
      })
    },
    // eslint-disable-next-line
    [getSession]
  );

  // LOGOUT
  const logout = useCallback(async () => {
    if (await getCurrentUser()) {
      await signOut();
      dispatch({
        type: 'LOGOUT',
      });
    }
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      token: state.token,
      tokenID: state.tokenID,
      login,
      logout,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      state.tokenID,
      state.token,
      login,
      logout,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
