import React, { useEffect, useState } from "react";

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  Card,
  CardContent,
  CardHeader, FormControl, InputLabel,
  Link,
  MenuItem, Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@mui/material";
import Paper from '@mui/material/Paper';
import useRequest from "../hooks/useRequest";


function MainRequest () {
  const {
    // isLoading,
    //
    getRequest: {
      status: statusGet,
      response: responseGet
    },
    postRequest: {
      status: statusPost,
      // response: responsePost
    },
    password,
    //
    // error,
    //
    provisionWB,
    getPassword,
    hidePassword,
    refresh
  } = useRequest();

  const [soc, setSoc] = useState(undefined);
  const [softwareproduct, setSoftwareProduct] = useState(undefined);
  const [appdeployment, setAppDeployment] = useState(undefined);

  useEffect(() => {
    if (statusPost === 200) {
      const timer = setInterval(() => {
        refresh({ soc, softwareproduct, appdeployment });
      }, 10000);

      return () => {
        clearInterval(timer);
        setDisabledButton(false)
      };
    }
  }, [statusPost]);

  useEffect(() => {
    if (!!password) {
      const timer = setInterval(() => {
        hidePassword();
      }, 60000);

      return () => {
        clearInterval(timer);
      };

    }
  }, [password]);

  const [disabledButton, setDisabledButton] = useState(true);

  const handleChangeSoc = (event) => {
    setSoc(event.target.value);
  };

  const handleChangeSoftwareProduct = (event) => {
    setSoftwareProduct(event.target.value);
  };

  const handleChangeAppDeployment = (event) => {
    setAppDeployment(event.target.value);
  };

  useEffect(() => {
    if (soc && softwareproduct && appdeployment) {
      setDisabledButton(false);
    }
  }, [soc, softwareproduct, appdeployment])

  return (
    <Stack spacing={5} direction="column" sx={{ minWidth: 768 }}>
      <Card sx={{ p: 2 }}>
        <CardHeader title="Workbench Configuration" titleTypographyProps={{ variant: "h4", textAlign: "center" }} />
        <CardContent>
          <Stack component="div" sx={{mt: 5, width: '100%', alignItems: 'flex-start',flexDirection: 'column', display: 'flex', gap: 5}}>
            <FormControl fullWidth>
              <InputLabel id="soc-label">SoC</InputLabel>
              <Select
                labelId="soc-label"
                id="soc"
                value={soc}
                label="SoC"
                onChange={handleChangeSoc}
              >
                <MenuItem value="SA8775">SA8775</MenuItem>
                <MenuItem value="SA8650">SA8650</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="softwareproduct-label">Software Product</InputLabel>
              <Select
                labelId="softwareproduct-label"
                id="softwareproduct"
                value={softwareproduct}
                label="Software Product"
                onChange={handleChangeSoftwareProduct}
              >
                {/*<MenuItem value="HGY+Linux+Android">HGY+Linux+Android</MenuItem>*/}
                <MenuItem value="HGY+Safe Linux+Android">HGY+Safe Linux+Android</MenuItem>
                <MenuItem value="QNX">QNX</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="appdeployment-label">App Deployment Type</InputLabel>
              <Select
                labelId="appdeployment-label"
                id="appdeployment"
                value={appdeployment}
                label="App Deployment Type"
                onChange={handleChangeAppDeployment}
              >
                <MenuItem value="Linux">Linux</MenuItem>
                <MenuItem value="Android">Android</MenuItem>
                <MenuItem value="Matlab">Matlab</MenuItem>
              </Select>
            </FormControl>

              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  setDisabledButton(true);
                  provisionWB({ soc, softwareproduct, appdeployment});
                }}
                disabled={disabledButton}
                sx={{ mt: 3 }}
              >
                Provision Workbench
              </Button>
          </Stack>
        </CardContent>
      </Card>

      <Card sx={{ p: 2 }}>
        <CardHeader title="Workbench Status" titleTypographyProps={{ variant: "h4", textAlign: "center"  }}/>
        <CardContent>
          <Stack direction="column" alignItems="flex-start" spacing={5} sx={{ mt: 5, width: 1 }}>
            {statusGet === 200 && responseGet?.WBProvisionedStatus === "Provisioned" ?
              <Stack sx={{ width: 1 }} spacing={2}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 3 }}
                  onClick={() => refresh({ soc, softwareproduct, appdeployment })}
                >
                  Refresh
                </Button>

                <Box>
                  <Typography variant="subtitle1" noWrap component="div">SoC : {soc}</Typography>
                  <Typography variant="subtitle1" noWrap component="div"> Software Product : {softwareproduct}</Typography>
                  <Typography variant="subtitle1" noWrap component="div">App Deployment Type : {appdeployment}</Typography>
                </Box>

                <TableContainer sx={{marginTop: '20px'}} component={Paper}>
                  <Table aria-label="simple table">
                    <TableBody>
                      {Object.entries(responseGet)?.filter(([key, _]) => key !== "WBProvisionedStatus" && key !== "GitHub")?.map(([key, value]) => (
                        <TableRow key={key}>
                          <TableCell component="th">{key}</TableCell>
                          <TableCell align="center">{value}</TableCell>
                        </TableRow>
                      ))}
                      {Object.entries(responseGet)?.filter(([key, _]) => key === "GitHub")?.map(([key, value]) => (
                        <TableRow key={key}>
                          <TableCell component="th">{key}</TableCell>
                          <TableCell align="center">
                            <Link href={value}>
                              Github Access
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow key="DCV Access">
                        <TableCell component="th">"DCV Access"</TableCell>
                        <TableCell align="center">
                          <Link href="https://download.nice-dcv.com">
                            Download DCV Client
                          </Link>
                        </TableCell>
                      </TableRow>
                      <TableRow key="User">
                        <TableCell component="th">"User"</TableCell>
                        <TableCell align="center">
                          ubuntu
                        </TableCell>
                      </TableRow>
                      <TableRow key="GetAccessPassword">
                        <TableCell component="th">"GetAccessPassword"</TableCell>
                        <TableCell align="center">
                          {password ? (
                            <Typography variant="body2">
                              {password}
                            </Typography>
                          ) : (
                            <Button
                              fullWidth
                              variant="contained"
                              onClick={() => {
                                getPassword({ soc, softwareproduct, appdeployment})
                              }}
                            >
                              Get Password
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
              : (
                <Typography
                  variant="h6"
                  textAlign="center"
                  width="100%"
                  noWrap
                  component="div"
                >
                  No workbenches are currently available.
                </Typography>
              )}

          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}

export default MainRequest;
